
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import EUIBasicUsage from "@/views/resources/documentation/element-ui/navigation/dropdown/BasicUsage.vue";
import EUITriggeringElement from "@/views/resources/documentation/element-ui/navigation/dropdown/TriggeringElement.vue";
import EUIHowToTrigger from "@/views/resources/documentation/element-ui/navigation/dropdown/HowToTrigger.vue";
import EUIMenuHidingBehavior from "@/views/resources/documentation/element-ui/navigation/dropdown/MenuHidingBehavior.vue";
import EUICommandEvent from "@/views/resources/documentation/element-ui/navigation/dropdown/CommandEvent.vue";
import EUISizes from "@/views/resources/documentation/element-ui/navigation/dropdown/Sizes.vue";

export default defineComponent({
  name: "dropdown",
  components: {
    EUIBasicUsage,
    EUITriggeringElement,
    EUIHowToTrigger,
    EUIMenuHidingBehavior,
    EUICommandEvent,
    EUISizes
  },
  setup() {
    setCurrentPageTitle("Dropdown");
  }
});
